﻿.signature {
    &.signature-small {
        .time {
            display: none;
        }
        p { display: inline-block; }

        .signature-area {
            max-width: 150px;
        }
    }
}

.signature-login {
    > img {
        border-radius: 100%;
        border: 2px solid white;
        box-shadow: 0 0 5px rgba(0,0,0,0.25);
        margin-right: $padding-base-horizontal;
        display: inline-block;
    }
}

.signature-signature {
    text-align: center;

    .signature-area {
        display: block;
        position: relative;

        img { max-width: 100%; }
        &:before {
            height: 2px;
            background: black;
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            top: 80%;
        }

    }

    p:nth-of-type(1) {
        padding-top: $padding-base-horizontal;
    }

    p {
        line-height: 1em;
    }
}