


@import "bootstrap/bootstrap.scss";


// @import "base/0__reset";
@import "base/1__breakpoint";
// @import "base/2__fontface";
@import "base/3__variables";
@import "base/4__variables_from_less";
@import "base/5__classes";
@import "base/6__big-divs";
// @import "base/7__animations";
// @import "base/8__when-visible.scss";
@import "base/9__loaders";



@import "elements/___dashio.scss";
@import "elements/__dashio-responsive.scss";
@import "elements/_app.scss";
@import "elements/attachment.scss";
@import "elements/buttons.scss";
@import "elements/calendar.scss";
@import "elements/datetimepicker.scss";
@import "elements/forms.scss";
@import "elements/header.scss";
@import "elements/items.scss";
@import "elements/menu.scss";
@import "elements/signature.scss";
@import "elements/signaturepad.scss";
@import "elements/tables.scss";
@import "prepages/all_less.scss";
@import "prepages/diff.scss";
@import "prepages/flow.scss";
@import "prepages/operations.scss";
@import "prepages/plan.scss";
@import "prepages/timeline.scss";
@import "prepages/visitors.scss";
@import "pages/admin-customers-edit.scss";
@import "pages/customers-areas.scss";
@import "pages/fabrication-data.scss";
@import "pages/forsendelse.scss";
@import "pages/fortoyning.scss";
@import "pages/gate-control-minimized.scss";
@import "pages/gate-control.scss";
@import "pages/gate-system.scss";
@import "pages/general.scss";
@import "pages/home.scss";
@import "pages/login-external.scss";
@import "pages/mottaks_snarveg_mottak.scss";
@import "pages/mottaks-oversikt2.scss";
@import "pages/mottaks-oversikt3.scss";
@import "pages/plan.scss";
@import "pages/returbrev.scss";


// @import "vendors/fontawesome/fontawesome.scss";
// @import "vendors/fontawesome/solid.scss";
// @import "vendors/fontawesome/regular.scss";
// @import "vendors/fontawesome/brands.scss";
// @import "vendors/fontawesome/v4-shims.scss";

@import "vendors/angular-chart.scss";
@import "vendors/angular-wizard.scss";
@import "vendors/font-awesome.scss";
@import "vendors/font-awesome45.scss";
@import "vendors/glyphicons.scss";
@import "vendors/perfect-scrollbar.scss";
@import "vendors/selectize.scss";


