



/*  FORMS CLEAN LAYOUT  */

.form-clean-layout-container {
    background: #fff;
    box-shadow: 0px 3px 2px #aab2bd;
    display: inline-block;
    width: 100%;
    .form-title {
        margin-top: 0;
        padding:35px 40px 0px;
        margin-bottom: 0px;
        @media only screen and (max-width: 600px) { 
            padding:35px 20px 0px;
        }
    }
}


form.form-clean-layout {
    padding: 30px 30px;
    max-width: 600px;
    margin-bottom: 100px;
    @media only screen and (max-width: 600px) { 
        padding: 30px 10px;
    }
    .form-subtitle {
        padding:20px 0px;
        font-weight: bold;
        display: inline-block;
        width: 100%;
    }
    p {
        padding:5px 10px;
        margin-bottom: 6px;
        clear: both;
        &.col {
            width: 50%;
            float: left;
            clear: none;
            @media only screen and (max-width: 600px) { 
                width: 100% !important;
                float: none;
            }
        }
    }
    label {
        display: block;
        font-weight: bold;
        font-size: 13px;
    }
}





form.form-clean-layout input:not([type=checkbox]),
form.form-clean-layout select {
    padding:5px 10px;
    width: 100%;
    height: 32px;
}

form.form-clean-layout input:focus,
form.form-clean-layout select:focus {
	border: 2px solid #ffbc18;
    outline: none !important;
}


form.form-clean-layout .checkbox-container {
	padding-top: 10px;
    display: inline-block;
    width: 100%;
    label {
        display: inline-block;
        float: right;
        padding-top: 2px;
        width: calc(100% - 20px);
        user-select: none;
    }
    input {
        float: left;
        display: inline-block;
        margin-top: 3px;
    }
}


form.form-clean-layout input[type=submit] {
    margin-top:20px;
    padding: 12px 20px 10px;
    background-color: green;
    color:#fff;
    border: 1px solid green;
    border-radius: 3px;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height:1;
    height: auto !important;
    &:hover {
        background-color: darkgreen;
        border: 1px solid darkgreen;
    }
}






form.form-clean-layout .draw-container {
	display: inline-block;
	width: 100%;
	padding: 5px 10px;
	padding-top: 15px;
	display: none;
    label {
        display: inline-block;
        float: left;
    }
    .draw-area canvas{
        display: inline-block;
        width: 100%;
        border: 1px solid rgba(0,0,0,0.5);
        height: 170px;
        @media only screen and (max-width: 600px) { 
        	height: 120px;
        }
    }
    .btn-clear {
        border: 1px solid orange !important;
        background: orange;
        color:#fff;
        margin-left: 10px;
        font-size: 10px;
        text-transform: uppercase;
        outline:none;
        float: left;
        transform: translateY(-5px);
        font-weight: bold;
        letter-spacing: 1px;
        padding: 4px 12px;
        &:hover {
            border: 1px solid darkorange !important;
            background: darkorange;
        }
        i {
            margin-right: 5px;
        }
    }
}








