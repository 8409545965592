


.view_container.gate_control {

	.content-panel.top {
		position: relative;
		@include brk($m) {
			display: inline-block;
			width: 100%;
		}
		.clock_container {
			position: absolute;
			bottom: 10px;
			right: 20px;
			@include brk($m) {
				position: relative;
				float: left; 
				bottom: auto;
				right: auto;
				padding-top: 10px;
				padding-left: 60px;
				padding-bottom: 15px;
			}
			button.refresh {
				background: #fff;
				padding: 8px 10px 7px;
				border-radius: 5px;
				border:1px solid transparentize(#000, 0.8);
				outline: none;
				margin-left: 10px;
				@include brk($m) {
					position: absolute;
					top: 0;
					left: 0;
					margin-left: 15px;
				}
				&:hover {
					background: $blue;
					border:1px solid $blue;
					color:#fff;
				};
			}
		}
	}
}


.row.container.gate_control,
.content-panel.gate_control {

	th {
		padding-left: 3px;
	}
	th, td {
		font-size: 13px;
	}

	table.table-striped td.td-apne {
		min-width: 100px;
	}
	table.table-striped td.td-description {
		min-width: 100px;
		color:#000;
	}
	table.table-striped td.td-soner {
		min-width: 110px;
	}

	table.table-striped td.td-permanent-tilstand {
		min-width: 320px;
		// min-width: 80px;
	}


	table.table-striped td.td-apne {
		button {
			background: green;
			background: $blue;
			color:#fff;
			padding: 10px 16px 8px 13px;
			border:none;
			min-width: 82px;
			text-align: left;
			margin: 3px;
			@include brk($m) {
				margin-left: 0;
				margin-right: 0;
				padding: 10px 13px 8px 10px;
				i {
					margin-right: 3px !important;
				}
			}
			&.basic {
				text-align: left;
				width: 300px;
				padding-top: 16px;
				padding-bottom: 16px;
				font-size: 15px;
				@include brk($m) {
					width: 210px;
					padding-top: 15px;
					padding-bottom: 15px;
					width: calc(100vw - 100px);
					font-size: 4.2vw;
					// width: auto;
				}
			}
			i {
				margin-right: 5px;
			}
			&:hover {
				background: darken($blue, 10%);
			};
		}
	}


	.td_opening_status {
		span {
			display: inline-block;
			&.opened, &.closed {
				font-size: 12px;
				color:#fff;
				padding: 5px 10px 4px;
				border-radius:10px;
			}
			&.opened {
				background: linear-gradient(to top, darken($green, 4%), $green);
			}
			&.closed {
				background: linear-gradient(to top, darken($orange, 4%), $orange);
			}
		}
	}


	ul.states {
		padding: 0;
		li {
			display: inline-block;
			float: left;
		}
		input[type="radio"] {
			display: none;
			&:checked+label{ 
				// color:#000; 
				// font-weight: 900;
				.case .inside {
					display: block;
				}
				.check {
					display: inline-block !important;
				}
				.overlay {
					display: inline-block !important;
				}
				color:#fff;
				&.auto {
					background: linear-gradient(to top, darken($blue, 4%), $blue);
				}
				&.always_opened {
					background: linear-gradient(to top, darken($green, 4%), $green);
				}
				&.always_closed {
					background: linear-gradient(to top, darken($red, 4%), $red);
				}
			} 
		}
		span.overlay {
			position: absolute;
			display: inline-block;
			width: 100%;
			height: 100%;
			left: 0;top: 0;
			display: none;
			background: transparentize(#000, 0.85);
		}
		span.state_name {
			position: relative;
		}
		label {
			position: relative;
			user-select: none;
			// background: $blue;
			// background: linear-gradient(to top, darken($blue, 4%), $blue);
			// background: linear-gradient(to right, $blue 0%, $blue 60% , $green 100%);
			display: inline-block;
			float: left;
			padding: 10px 10px 9px;
			font-size: 12px;
			margin-bottom: 0;
			opacity: 0.3;
			border-radius:3px;
			overflow: hidden;
			font-weight: 700;
			background: #fff;
			margin-right: 5px;
			box-shadow: 1px 1px 20px transparentize(#000, 0.9);
			i {
				margin-right: 3px;
				font-size: 10px;
				display: none;
				&.fa-spin {
					display: inline-block;
				}
			}
			&:not(.disabled) {
				opacity: 1;
				cursor: pointer;
				&:hover {
					box-shadow: 1px 1px 10px transparentize(#000, 1);
					.overlay {
						display: inline-block !important;
					}
					color:#fff;
					&.auto {
						background: linear-gradient(to top, darken($blue, 4%), $blue);
					}
					&.always_opened {
						background: linear-gradient(to top, darken($green, 4%), $green);
					}
					&.always_closed {
						background: linear-gradient(to top, darken($red, 4%), $red);
					}
				}
			};
		}
	}


	// label.state_switch {
	// 	float: left;
	// 	padding-top: 6px;
	// 	margin-right: 15px;
	// 	cursor: pointer;
	// 	margin-bottom: 0;
	// 	.state_name {
	// 		float: left;
	// 		// user-select: none;
	// 		&.selected {
	// 			color:#000;
	// 		}
	// 	}
	// 	.yoga_switch {
	// 		width: 32px;
	// 		// display: none !important;
	// 		height: 20px;
	// 		background: darken(#fff, 10%);
	// 		border-radius: 10px;
	// 		float: left;
	// 		margin-right: 5px;
	// 		padding: 2.5px;
	// 		overflow: hidden;
	// 		margin: 0 10px;
	// 		transform: translateY(-1px);
	// 		&::after {
	// 			content:"";
	// 			display: inline-block;
	// 			height: 15px;
	// 			width: 15px;
	// 			border-radius:50%;
	// 			background: #fff;
	// 			transition: transform 0.2s;
	// 			box-shadow: 1px 1px 10px transparentize(#000, 0.6);

	// 		};
	// 	}
	// 	input[type="checkbox"] {
	// 		display: none;
	// 		&:checked + .yoga_switch {
	// 				background: $green;
	// 				// background: #000;
	// 				&::after {
	// 					transform: translateX(12px);
	// 				};
	// 		}
	// 		&:hover {
	// 			// background: $orange;
	// 		};
	// 	}
	// }



	button.always_closed {
		float: left;
		background: lighten($red, 5%);
		border:none;
		color:$white;
		padding: 6px 7px 5px;

		i {
			margin-right: 2px;
		}
		&:hover, &:focus {
			background: darken($red, 5%);
		};
	}
}














.modal_view.gate_control {


	
}