


.external_environment {

	.login-logo {
		@include brk($m) {
			width: 150px !important;
		}
	}

	.view_container.gate_control_minimized {
		padding-top: 5vh;
		max-width: 600px;
		margin: 0 auto;
		&.basic {
			max-width: 600px;
		}
		@include brk($m) {
			padding-top: 3vh;
		}
		@include brk($sm) {
			padding-top: 0vh;
		}
		.content-panel {
			border-radius: 5px;
			box-shadow: 1px 1px 35px transparentize(#000, 0.5);
			@include brk($m) {
				border-radius: 0;
				box-shadow: none;
			}
			table.table-striped td.td-permanent-tilstand {			
				min-width: 80px;
			}
		}
		.content-panel.top {
			margin-bottom: 30px;
			padding-top: 3px;
			padding-bottom: 0px;
			@include brk($m) {
				margin-bottom: 0px;
			}
			h1 {
				@include brk($m) {
					margin-bottom: 10px;
				}
			}
		}
		section.content-panel {
			padding: 20px 17px 15px;
			z-index: 0;
			@include brk($m) {
				padding: 10px 2% 15px;
			}
		}
	}
}



.view_container.gate_control_minimized {
	margin-bottom: 30px;
	h1 {
		display: inline-block;
		width: 100%;
		@include brk($m) {
			margin-bottom: 0;
		}
	}


	.content-panel {
		@include brk($m) {
			overflow: hidden;
			width: 110% !important;
			margin-left: -5%;
		}
	}

	@include brk($m) {
		.content-panel.top {
			padding-top: 5px;
			box-shadow: none;
		}
		td {
			min-width:0 !important;
		}
	}


	.content-panel.top {		
		padding-top: 30px;
		padding-bottom: 0;
	}


	section.content-panel {
		margin-top: 0;
		z-index: 1;
		position: relative;
		padding: 0px 17px 15px;
		@include brk($m) {
			margin-top: -6px;
			padding: 10px 2% 15px;
		}

		td.td-description {
			min-width: 240px !important;
			@include brk($m) {
				min-width:0 !important;
			}
		}
		.table-striped-container {
			overflow: hidden;
		}

		td.td-permanent-tilstand {
			padding-bottom: 0;

			label {
				padding-top: 5px;
				margin-bottom: 0;
				cursor: pointer;
			}
			input[type=checkbox] {
				width: 20px;
				height: 20px;
			}
			input {
				display: none;
				&:checked + .yoga_switch {
					background: $blue;
					&::after {
						transform: translateX(13px);
					};
				}
			}


			.yoga_switch {
				width: 34px;
				height: 20px;
				background: darken(#fff, 10%);
				// border: 1px solid darken(#fff, 30%);
				border-radius: 12px;
				float: left;
				margin-right: 5px;
				padding: 2.5px;
				overflow: hidden;
				&::after {
					content:"";
					display: inline-block;
					height: 15px;
					width: 15px;
					border-radius:50%;
					background: #fff;
					transition: transform 0.2s;
					box-shadow: 1px 1px 10px transparentize(#000, 0.6);
				};
			}

		}
	}
}
