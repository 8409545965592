





form#fortoyning_register {
	// border-top: 1px solid transparentize(#000, 0.9);
	padding-left: 50px;
	.small_title {
		margin: 0;
		margin-bottom: 20px;
		font-weight: 400;
		font-size: 18px;
	}
	p {
		padding: 0;
		padding-top: 5px;
		input[type="submit"] {
			margin-top: 0;
			background: grey;
			background: transparentize(#000, 0.7);
			background: linear-gradient(to top, transparentize(#000, 0.65), transparentize(#000, 0.7));
			border-color: transparent;
			padding: 15px 20px 12px;
			&:hover {
				background: linear-gradient(to top, transparentize(#000, 0.45), transparentize(#000, 0.5));
			};
		}
	}
	ul {
		display: inline-block;
		width: 100%;
		padding-left: 0px;
		li {
			display: inline-block;
			width: 100%;
			float: left;
			height: 50px;
			margin-bottom: 5px;
			position: relative;
			&.is_available {
				label {
					background: $green;
					background: linear-gradient(to top, darken($green, 3%), $green);
				}
				input[type="radio"]:checked+label{ 
				} 
			}
			span {
				color: white;
				&.check {
					position: absolute;
					top: 12px;
					left: 17px;
					display: none;
				}
				
				&.name {
					position: relative;
				}
				&.state {
					position: absolute;
					top: 17px;
					left: 150px;
					font-size: 13px;
					text-transform: uppercase;
					letter-spacing: 1px;
					font-weight: 400;
				}
				
				&.overlay {
					position: absolute;
					display: inline-block;
					width: 100%;
					height: 100%;
					left: 0;top: 0;
					display: none;
					background: transparentize(#000, 0.85);
				}
			}
			input[type="radio"] {
				display: none;
				&:checked+label{ 
					font-weight: 900;
					.case .inside {
						display: block;
					}
					.check {
						display: inline-block !important;
					}
					.overlay {
						display: inline-block !important;
					}
				}
			} 
			label {
				position: relative;
				background: $blue;
				background: linear-gradient(to top, darken($blue, 4%), $blue);
				// background: linear-gradient(to right, $blue 0%, $blue 60% , $green 100%);
				display: inline-block;
				width: 100%;
				padding-top: 12px;
				font-size: 20px;
				height: 50px;
				margin-bottom: 0;
				cursor: pointer;
				color:#fff;
				padding-left: 50px;
				border-radius:10px;
				overflow: hidden;
				font-weight: 900;
				box-shadow: 1px 1px 20px transparentize(#000, 0.9);
				&:hover, &:focus {
					box-shadow: 1px 1px 10px transparentize(#000, 1);
					.overlay {
						display: inline-block !important;
					}
				};
			}
		}
	}
}




form#fortoyning_update {
	label {
		font-size: 15px;
	}
	p.date_container {
		position: relative;
		width: 75%;
		@include brk($m) {
			width: 93%;
			margin-bottom: 30px;
		}
		.focus_button {
			background: linear-gradient(#0078c9,#116ab0);
			display: inline-block;
			position: absolute;
			text-align: center;
			width: 100px;
			height: 100px;
			top: -0px;
			right: -17px;
			border-radius:50%;
			padding-top: 11px;
			font-size: 10px;
			font-weight: 900;
			color:#fff;
			i {
				position: absolute;
				bottom: 15px;
				left: 45px;
				transform: rotate(180deg);
			}
		};
		input {
			position: relative;
			height: 40px;
		}
		&:hover {
			.focus_button {
				// background: darken($green, 3%);
			}
		};
	}
	select {
		height: 40px;
	}
	input[type="submit"] {
		margin-top: 0;
		background: grey;
		background: transparentize(#000, 0.7);
		border-color: transparent;
		padding: 15px 20px 12px;
		&:hover {
			background: transparentize(#000, 0.5);
		};
	}
	.is_help_container {
		width: 50%;
		@include brk($m) {
			width: 100%;
		}
		label {
			height: 42px;
			padding: 10px;
			cursor:pointer;
			border:1px solid transparentize(#000, 0.8);

			position: relative;
			// padding-left: 50px;
			font-size: 15px;
			// border-radius:10px;
			background: #fff;
			background: linear-gradient(to top, darken(#fff, 2%), darken(#fff, 0%));
			user-select: none;
			// &:hover {
			// 	background: lighten($green, 5%);
			// 	color:$white;
			// 	border:1px solid lighten($green, 5%);
			// };
			i {
				position: absolute;
				top: 10px;
				left: 55px;
				left: 15px;
				font-size: 20px;
				margin-right: 5px;
				// display: none;
			}
			.label_span {
				padding-left: 40px;
				display: inline-block;
			}
		}
		input {
			display: none;
		}
		input[type="checkbox"] {
			&:checked+label{ 
				background: darken($green, 3%);
				background: linear-gradient(to top, darken($green, 5%), darken($green, 3%));
				border:1px solid darken($green, 3%);
				color:$white;
			}
			&:hover {
				// background: $orange;
			};
		}


	}

}