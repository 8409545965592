﻿.datetimepicker-date {
    display: inline-block;
    width: 130px;
    vertical-align: top;
}

.datetimepicker-time {
    display: inline-block;
    width: 100px;
    vertical-align: top;
    margin-left: $padding-base-horizontal;

    table {
        border-collapse: collapse;

        .form-group {
            border-bottom: 0 !important;
            padding-bottom: 0 !important;
        }
    }
}

.datetimepicker-now {
    display: inline-block;
    margin-left: $padding-base-horizontal;
    padding-top: 23px;
}