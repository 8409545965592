












div.table-striped-container {
	overflow-x: scroll;
	position: relative;
	max-width: 100%;

	> table.table-td-verticaly-centered tbody td,
	> table.table-striped tbody td {
	    vertical-align: middle !important;
	}

	th {
		font-size: 14px;
		padding-left: 5px;
	}

}

table.table-striped tbody p {
    font-size: 16px !important;
    margin-bottom: 0 !important;
}








/*  TRANSVERSAL  */

table.table-striped td.td-deklarasjonsnummer {
	min-width: 110px;
}
table.table-striped td.td-deponiOpprinnelse {
	min-width: 150px;
}
table.table-striped td.td-deponiType {
	min-width: 155px;
}
table.table-striped td.td-oi {
	min-width: 50px;
}
table.table-striped td.td-pb210 {
	min-width: 60px;
}
table.table-striped td.td-fatidkunde {
	min-width: 100px;
}
table.table-striped td.td-center {
	text-align: center;
}
table.table-striped td.td-name {
	min-width: 140px;
}
table.table-striped td.td-soner { // now in gate-control.scss
	min-width: 250px;
}

table.table-striped td.td-company-name {
	min-width: 120px;
}
table.table-striped td.td-varer {
	min-width: 140px;
}

td.td-date {
	min-width: 80px;
}
td.td-date-long {
	min-width: 130px;
}

table.table-striped td.td-avfallsprodusent {
	min-width: 190px;
}

table.table-striped td.td-retur {
	min-width: 100px;
}
table.table-striped td.td-emballm {
	min-width: 180px;
}
table.table-striped td.td-pikkep {
	min-width: 125px;
}
table.table-striped td.td-ealkode {
	min-width: 80px;
}
table.table-striped td.td-fakturanummer {
	min-width: 130px;
}
table.table-striped td.td-buttons {
	min-width: 90px;
}
table.table-striped td.td-type-name {
	min-width: 80px
}
table.table-striped td.td-vessels-index-buttons {
	min-width: 110px;
}
table.table-striped td.td-apne { // now in gate-control.scss
	min-width: 110px;
}
table.table-striped td.td-permanent-tilstand {// now in gate-control.scss
	min-width: 360px;
}
table.table-striped td.td-description {// now in gate-control.scss
	min-width: 200px;
}
table.table-striped td.td-kommentar {
	padding-left: 15px !important;
	min-width: 190px;
}




/*  Returbrev  */

table.table-striped.returbrev td.id {
	min-width: 50px;
}
table.table-striped.returbrev td.deklarasjon {
	min-width: 145px;
}
table.table-striped.returbrev td.deklarasjon span {
	margin: 0 5px;
}
table.table-striped.returbrev td.baat {
	min-width: 135px;
}
table.table-striped.returbrev td.dato {
	min-width: 100px;
}





/* BULKVARER */
div.table-striped-container.bulkvarer td {
	padding: 4px 8px !important;
}








@media only screen and (max-device-width: 480px) { 


	/*  GENERAL  */

    table.table-striped tbody p {
        font-size: 14px !important;
    }


}


