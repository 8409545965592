







.wizard-panel .steps {
	td {
		input[type="checkbox"] {
			margin-left: 4px;
		}
	}
	input[type="checkbox"] {
		display: inline-block !important;
	}
}