﻿$circle-diameter: 84px;

.flow {
    display: block;
    padding-bottom: $padding-base-vertical;

    > * {
        display: inline-block;
        vertical-align: middle;
    }

    &.reverse {
        .flow-group + .flow-group:before {
            content: "\f053";
        }
        @media screen and (max-width: $screen-sm-max) {
            .flow-group + .flow-group:before {
                content: "\f077";
            }
        }
    }
}

.flow-direction {
    padding: 0 $padding-base-horizontal;
}

.flow-group {
    display: inline-block;

    + .flow-group:before {
        display: inline-block;
        content: "\f054";
        font-family: 'FontAwesome';
        font-size: 18px;
        vertical-align: top;
        height: 84px;
        line-height: 84px;
    }

    @media screen and (max-width: $screen-sm-max) {
        display: block;
        text-align: center;

        + .flow-group:before {
            display: block;
            content:"\f078";
            height: auto;
            line-height: inherit;
        }
    }

}

.flow-item {
    border-radius: $circle-diameter / 2;
    height: $circle-diameter;
    min-width: $circle-diameter;
    display: inline-block;
    text-align: center;

    // when printed, the background also applies to child elements, which are wider than the circle...
    overflow: hidden;

    background: #eaeaea !important; // overriding print styles must be !important
    * { color: #2f323a !important; }
    h3 {
        margin-top: 20px !important;
        margin-bottom: 0;
    }
    p {
        font-size: $font-size-small;
        // the circle is smaller below the middle, so extend padding
        padding: 0 10px;
    }

    &.primary {
        background: $brand-primary !important; // overriding print styles must be !important
        * { color: white !important; }
    }

    &.warning {
        background: $brand-warning !important; // overriding print styles must be !important
        * { color: white !important; }
    }
}