﻿$step-marker-size: 10px;
$step-circle-radius: 10px;

.steps-indicator {
    display: block;
    background: $brand-primary !important; // override print styles
    padding: 0 $padding-large-horizontal;
    list-style-type: decimal;
    counter-reset: steps;
    margin-top: -1px;
    overflow: hidden;

    a {
        color: white;
    }

    li {
        color: white !important; // override print styles
        display: inline-block;
        margin-right: $padding-base-horizontal;
        display: inline-block;
        position: relative;
        padding: $padding-base-vertical * 2 0;
        font-weight: bold;

        &::before {
            color: $brand-primary !important; // override print styles
            content: counter(steps);
            counter-increment: steps;
            background: white !important; // override print styles
            display: inline-block;
            width: $step-circle-radius * 2;
            height: $step-circle-radius * 2;
            border-radius: $step-circle-radius;
            text-align: center;
            line-height: $step-circle-radius * 2;
            margin-right: $padding-base-horizontal / 2;
        }

        &::after {
            transition: all 0.25s ease-in-out;
            display: block;
            content: '';
            width: $step-marker-size;
            height: $step-marker-size;
            transform: rotateZ(45deg);
            position: absolute;
            bottom: -$step-marker-size - 2px;
            background: white !important; // override print styles
            left: $step-circle-radius / 2;
        }


        &.editing, &.current {
            transition: all 0.25s ease-in-out;

            &::after {
                bottom: -$step-marker-size / 2;
            }
        }

        &:not(.completed) {
            opacity: 0.8;
        }

        &.completed, &.completed + li, &.current, &.editing {
            cursor: pointer;
            opacity: 1;
        }

        &.checked::before {
            font-family: FontAwesome;
            content: "\f00c";
            color: white !important; // override print styles
            background: $brand-success !important; // override print styles
        }
    }

    &.below {
        li::after {
            bottom: inherit;
            top: -$step-marker-size * 2;
        }

        li.current::after {
            bottom: inherit;
            top: -$step-marker-size - 2px;
        }

        margin-bottom: -5px;
    }

    &.nonav {
        li {
            cursor: default !important;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .steps-indicator li::after { display: none; }
}
