




#customers_areas .row.container.customers-areas {
	padding-top: 0px;
	// max-width: 1200px;
	min-height: auto;
	background: #fff;
	box-shadow: 0px 3px 2px #aab2bd;
	width: 100% !important;
    padding-left: 0px; 
    padding-right: 0;
    margin: 0;
	margin-bottom: 30px;

	.title-container {
		display: inline-block;
		width: 100%;
		padding: 30px 20px 10px;
		@include brk($m) {
			padding-bottom: 13px;
		}
		.title {
			display: inline-block;
			float: left;
			margin: 0;
			padding: 0;
			width: 100%;
			user-select: none;
			padding-bottom: 18px;
			margin-bottom: 22px;
			border-bottom: 1px solid #ddd;
		    .fa-dashboard {
				font-size: 30px !important;
			}
			@include brk($m) {
				margin-bottom: 20px;
			}
		}
		.edit-container {
			width: 100%;
			float: right;
			padding: 0px;
			display: none;
			min-height: 46px;
			&.show-me {
				display: inline-block;
			}
			@include brk($m) {
				float: left;
				width: 100%;	
			}

			.notification {
				padding: 10px 15px;
				display: none;
				float: right;
				font-weight: $bold;
				&.red {
					color:$red;
				}
				&.green {
					color:$green;
				}
				@include brk($m) {
					float: left;
				}
				i {
					margin-right: 5px;
				}
				&.show-me {
					display: inline-block;
				}
			}
			button {
				background: green;
				color: #fff;
				padding: 10px 15px;
				font-weight: bold;
				border: 1px solid green;
				float: right;
				height: 40px;
				margin-left: 5px;
				border-radius:3px;
				overflow: hidden;
				outline: none;
				display: none;
				margin-bottom: 6px;
				user-select: none;
				i {margin-right: 10px;}
				@include brk($m) {
					float: left;
					margin-bottom: 5px;
					margin-left: 0;
					margin-right: 5px;
				}
				&.show-me {
					display: inline-block;
				}
				&.draw-area	 {
					background: green;
					border: 1px solid green;
					&:hover, &:visited, &:active, &.is-active {
						border: 1px solid darken(green, 10%);
						background: darken(green, 10%) !important;
					};
				}
				&.delete-existing-area {
					background: $red;
					border: 1px solid $red;
					&:hover, &:visited, &:active, &.is-active {
						border: 1px solid darken($red, 10%);
						background: darken($red, 10%) !important;
					};
				}
				&.edit-existing-area {
					background: $blue;
					border: 1px solid $blue;
					&:hover, &:visited, &:active, &.is-active {
						border: 1px solid darken($blue, 10%);
						background: darken($blue, 10%) !important;
					};
				}
				&.cancel {
					background: $orange;
					border: 1px solid $orange;
					&:hover, &:visited, &:active {
						border: 1px solid darken($orange, 10%);
						background: darken($orange, 10%);
					}
				}
				&.delete-this-area {
					background: $red;
					border: 1px solid $red;
					&:hover, &:visited, &:active {
						border: 1px solid darken($red, 10%);
						background: darken($red, 10%);
					}
				}
				&.save-area	 {
					background: $green;
					border: 1px solid $green;
					&:hover, &:visited, &:active {
						border: 1px solid darken($green, 10%);
						background: darken($green, 10%) !important;
					};
				}
			}
			input.custom_area_name {
				float: right;
				height: 40px;
				margin-left: 5px;
				padding:5px 10px;
				display: none;
				&.show-me {
			    	display: inline-block;
			    }
			}
			select {
			    padding:5px 10px;
			    margin-left: 5px;
			    width: 100%;
			    height: 32px;
			    max-width: 200px;
			    float: right;
			    height: 40px;
			    display: none;
			    max-height: 200px;
			    border: 2px solid $green;
				outline: none !important;
		    	background: $green;
			    border:2px solid $green;
			    border-radius: 3px;
			    color:#fff;
			    option {
			    	color:#000;
			    	background: #fff;
					font-size: 15px;
			    }
			    @include brk($m) {
					float: left;
					margin-bottom: 5px;
					margin-left: 0;
					margin-right: 5px;
				}
			    &.show-me {
			    	display: inline-block;
			    }
			}
		}
	}



	#customer_area_pre_selector {
		width: 265px;
		display: inline-block;
		clear: both;
		float: left;
		position: relative;
		margin-left: 0px;
		// border: 1px solid transparentize(#000, 0.9);
		margin-bottom: 6px;
		@include brk($m) {
			width: calc(100% - 100px);
			margin-bottom: 8px;
		}
	}

	.map {
		position: relative;
	    display: inline-block;
	    width: 100%;
	    height: 575px;
	    float: left;
	    overflow: hidden;
	    @include brk($m) {
			height: 425px;
		}
	    .angular-google-map-container {
			height: 600px;
			@include brk($m) {
				height: 450px;
			}
			// zoom control
			.gm-bundled-control {
				bottom: auto !important;;
			}

			// Controls
	        @keyframes gm_show_right_control {
	            0% {opacity:0; transform: translate3d(40px,11px,0)}
	            100% {opacity:1; transform: translate3d(-11px,11px,0)}
	        }
	        .gm-bundled-control {
	        	animation-fill-mode: both !important;
	            animation: gm_show_right_control 1s;
	            @include brk($m) {display:none!important}
	        }


			// map labels
			canvas.map-label-canvas {
				transition: opacity 0.3s !important;
			}
		}
		.note {
			padding: 10px 22px;
			// display: none;
			position: absolute;
			top: 0px;
			left: 0px;
			background: #065696;
			color:#fff;
			transform: translateY(-110%);
			transition: transform 0.3s;
			border-bottom-right-radius: 10px;
			// font-weight: $bold;
			i {margin-right: 5px;}
			@include brk($m) {
				font-size: 11px;
				max-width: 60%;
			}
			&.show-me {
				transform: translateY(0%);
				display: inline-block;
			}
		}
	}
	
}




