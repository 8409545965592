﻿.cover .attachment {
    position: relative;
    overflow: hidden;

    * { z-index: 10; position: relative; }

    &.attachment-image {
        file-icon { opacity: 0; }

        file-icon, h4, p { color: white; text-shadow: 0 0 10px rgba(0,0,0,0.5); }
    }

    .bg {
        z-index: 0;
        position: absolute;
        left: 50%;
        margin-left: -50%;
        margin-top: -50%;
        top: 50%;
        width: 100%;
        min-height: 100%;
    }
}

.attachment-event {
    position: relative;

    .actions {
        position: absolute;
        bottom: $padding-base-horizontal;
        right: $padding-base-horizontal;
    }

    file-icon {
        position: absolute;
        padding-top: 14px;
        left: $padding-base-horizontal;
    }

    h4, .user {
        margin-left: 60px;
    }
    h4 {
        padding-top: 6px;
        margin-bottom: 0;
    }

    .attachment {
        margin: -7px -15px 15px;
    }

    &.has-image {
        .attachment .bg {
            width: 100%;
        }
        file-icon { opacity: 1; }
    }
}