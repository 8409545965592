




/*  Returbrev  */

table.table-striped.returbrev {
	td.id {
		min-width: 50px;
	}
	td.deklarasjon {
		min-width: 145px;
		span {
			margin: 0 5px;
		}
	}
	td.baat {
		min-width: 135px;
	}
	td.dato {
		min-width: 100px;
	}
}




.app-returbrev-window .modal-dialog {
	max-width: 100%;
    width: 70%;
    @include brk($m) {
    	width: 100%;
    }
    .modal-content {
	    width: 100%;
	}
}


