






body.body_modal_hentelister {
	.modal {
		overflow-y: scroll;
		.modal-dialog {
			width: 100%;
			max-width: 900px;
		}
	}
}




.content-panel.waste_containers_view {

	.actions_container {
		a, button {
			padding: 7px 10px 6px;
			font-size: 13px;
			color:#fff;
			border:none;
			border-radius: 6px;
			i {
				margin-right: 3px;
			}
		}
		a.historikk {
			background: $purple;
			&:hover {
				background: darken($purple, 5%);
			};
		}
		button.washed {
			background: lighten($blue, 15%);
			&:hover {
				background: lighten($blue, 10%);
			};
		}
	}
	table.table-striped {

		th.returfrist {
			max-width: 130px;
		}

		td.number.td-buttons {
			min-width: 130px; 
			a.btn_historikk {
				padding: 3px 8px;
				font-size: 15px;
				background: $purple;
				color:#fff;
				border:none;
				&:hover {
					background: darken($purple, 10%);
				};
			}
			button.btn_wash {
				font-size: 15px;
				background: lighten($blue, 15%);
				padding: 3px 9px;
				border:none;
				color:#fff;
				&:hover {
					background: lighten($blue, 10%);
				};
			}
		}
	}
}