



#home_dashboard {
	padding-top: 0px;
	max-width: 1200px;
	.title-container {
		background: #fff;
		box-shadow: 0px 3px 2px #aab2bd;
		padding: 30px 20px 10px;
		.title {
			margin-bottom: 0;
		}
	}
	.row.container {
		width: 100% !important;
	    padding-left: 0px; 
	    padding-right: 0;
	    margin: 0;
		margin-bottom: 30px;
	}
	h1 {
		margin-top: 0;
	    padding-bottom: 23px;
	    .fa-dashboard {
			font-size: 30px !important;
		}
	}

}





#home_dashboard .row.container.infos {
	.title-container {
		
		
		position: relative;
		.title {
			padding-bottom: 20px;
		}
		// background: red;

		button {
			width: 100px;
			height: 100%;
			position: absolute;
			top: 0;
			right: 0;
			cursor:pointer;
			text-align: center;
			border:none !important;
			outline: none;
			background: none;
			i {
				font-size: 35px;
				transition: color 0.3s;
			}
			&:hover {
				i {
					color:#000;
				}
			};
		}
	}

	.iframe_container {
		display: inline-block;
		min-height: 600px;
		position: relative;
		width: 100%;
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			height: 100%;
			width: 100%;
			cursor: pointer;
			button.leave {
				width: 100px;
				height: 100px;
				position: absolute;
				top: 0;
				right: 0;
				cursor:pointer;
				text-align: center;
				border:none !important;
				outline: none;
				background: none;
				display: none;
				i {
					font-size: 35px;
					transition: color 0.3s;
					color:transparentize(#fff, 0.7);
				}
				@include brk($td) {
					&.show_me {
						display: inline-block !important;
					}
				}
			}
		}
		iframe {
			width: 100%;
			height: 100%;
			min-height: 600px;
			outline: none !important;
			border:none !important;


		}
		&.fullscreen {
			z-index: 1003;
			iframe, .overlay {
				position: fixed;
				top: 0;
				left: 0;
			}
		}
	}
}

















#home_dashboard .row.container.gate_control {
	.content-panel {
		margin-top: 0;
		padding: 10px 20px 10px;

		.table-striped-container {
			border-top: 1px solid #ddd;
			padding-top: 15px;
		}

	}
	.title-container {
		position: relative;
		box-shadow: none;
		.clock_container {
			position: absolute;
			bottom: 10px;
			right: 20px;
			@include brk($m) {
				position: relative;
				float: left; 
				bottom: auto;
				right: auto;
				padding-top: 10px;
				padding-left: 60px;
				padding-bottom: 15px;
			}
			button.refresh {
				background: #fff;
				padding: 8px 10px 7px;
				border-radius: 5px;
				border:1px solid transparentize(#000, 0.8);
				outline: none;
				margin-left: 10px;
				@include brk($m) {
					position: absolute;
					top: 0;
					left: 0;
					margin-left: 15px;
				}
				&:hover {
					background: $blue;
					border:1px solid $blue;
					color:#fff;
				};
			}
		}
	}
}
















#home_dashboard .row.container.find-customers {
	.title-container {
		box-shadow: 0px 3px 2px #aab2bd;
	}


	#search_container {
		padding-top: 15px;
		border-top: 1px solid #ddd;
		position: relative;
		.icon {
			width: 25px;
			height: 25px;
			position: absolute;
			left: 0;
			top: 30px;
			opacity: 0.3;
		}
		#search_input {
			padding: 17px 40px 15px;
			font-size: 18px;
			line-height: 1;
			margin-bottom: 0px;
			background: transparent;
			border:none !important;
			box-sizing: border-box;
			outline: none;
			width: calc(100%);
			/*box-shadow: 0px 3px 2px #aab2bd;*/
			/*text-align: center;*/
			&::placeholder {
				color:#aaa;
			}
			&::placeholder:focus {
			    border:none !important;
			}

		}
	}
	
	ul {
	    padding-left: 0;
	    width: calc(100% + 15px);
	    display: inline-block;
	    margin-top: 30px;
	    animation: fade 0.5s;
	    li {
		    margin-bottom: 10px;
		    padding-left: 0;
		    &:last-child {
	    		margin-bottom: 50px;
		    };
		    &.opened {
			    z-index: 5;
			}
		    .content-panel {
			    padding-top: 0 !important;
			    margin-bottom: 0;
			    position: relative;
			    box-shadow: 0px 3px 2px #bbb;
			    &:hover {
					background: #f4f4f4;
					 box-shadow: 0px 3px 1px #ccc;
				}
				header {
				    display: inline-block;
				    width: 100%;
				    cursor:pointer;
				    h3 {
					    font-size: 20px;
					    margin: 0 !important;
					    padding: 15px 15px 10px !important;
					}
				}
			}
			.customer-panel-commands {
		    	display:none;
			    position: absolute;
			    top: 100%;
			    left: 0;
			    width: 100%;
			    z-index: 10;
			    box-shadow: 0px 5px 12px #555;
			    .customer-panel-command {
			    	border-bottom: 1px solid transparentize(white, 0.8);
			    	height: 48px;
    				padding: 14px;
    				background: lighten($blue, 5%);
			    	&:hover {
				    	background: #034070;
				    }
				}
				.customer-panel-command-text {
					display: inline !important;
				}

		    }
		}
	}
}





