﻿$calendar-border: $gray-lighter;
$calendar-disabled: $gray-lighter;
$calendar-border-width: 1px;
$calendar-date-padding: 6px;

$calendar-events-height: 10px;
$calendar-event-size: 16px;

.calendar {
    h1 {
        text-align: center;
        font-weight: lighter;
        font-size: $font-size-base;
    }

    table {
        min-width: 280px;
        table-layout: fixed;

        td, th {
            width: 12.5%;
        }
    }

    th.weekDay { text-align: center; }

    th.week {
        text-align: right;
        padding-right: $padding-base-horizontal;
    }

    tr:last-child td.day {
        border-bottom: $calendar-border-width solid $calendar-border;
    }

    td.day {
        border-left: $calendar-border-width solid $calendar-border;
        border-top: $calendar-border-width solid $calendar-border;
        padding: $calendar-date-padding;
        font-size: 10pt;
        text-align: right;
        vertical-align: top;
        height: 30px;

        .day {
            font-size: $font-size-small;
        }
        &:last-child {
            border-right: $calendar-border-width solid $calendar-border;
        }

        &.disabled {
            color: $calendar-disabled;
        }

        &.today {
            background-color: $gray-light;
            color: white;
        }
    }
}

.events, .calendar-legend {
    .range, .event .point {
        display: inline-block;
        height: $calendar-events-height;
        background: $gray-lighter;

        &.primary { background: $brand-primary; }
        &.success { background: $brand-success; z-index: 10; }
        &.warning { background: $brand-warning; z-index: 20; }
        &.danger { background: $brand-danger; z-index: 30; }
    }

    .event .point {
        border: 2px solid white;
        box-shadow: 0 0 5px rgba(0,0,0,0.25);
        border-radius: 100%;
        width: $calendar-event-size;
        height: $calendar-event-size;
    }
}

.events {
    margin: 0 -$calendar-date-padding -$calendar-date-padding -($calendar-date-padding + $calendar-border-width);
    text-align: left;
    height: $calendar-events-height;
    position: relative;
    background-color: $gray-lighter;

    .range {
        position: absolute;
        background: $brand-success;
        height: $calendar-events-height;
        transition: transform 0.25s ease-in-out;
        &.hover {
            transform: scaleY(1.5);
        }
    }

    .event {
        position: absolute;
        margin-left: -$calendar-event-size / 2;
        top: ($calendar-events-height - $calendar-event-size) / 2;

        &:hover .point {
            transform: scale(1.5);
        }

        .point {
            background: $brand-primary;
            transition: transform 0.25s ease-in-out;
        }
    }
}

.calendar-scroller {
    vertical-align: middle;
    margin: 0 10px;

    display: flex;
    .calendar-container {
        padding: $padding-base-vertical $grid-gutter-width / 2;
    }

    .arrow {
       display: flex;
       flex-direction: row;
       width: 50px;

       .btn {
        flex-grow: 1;
       }
    }
}

.calendar-legend {
    text-align: center;
    border: 1px solid $gray-lighter;
    padding: $padding-base-vertical $padding-base-horizontal;
    margin: $padding-base-vertical $padding-base-horizontal;
    div {
        display: inline-block;
        margin: 0 $padding-base-horizontal;
    }
    .range {
        width: $calendar-events-height;
    }
}

.popover {
    overflow: hidden;
}

.calendar-popover {
    min-width: 200px;
    color: $gray-darker;

    .angular-google-map-container {
        width: 230px;
        height: 200px;
        margin: -10px -15px 10px -15px;
    }
}

.calendar-scroller calendar {
    flex: 1;

    table { width: 100%; }
}