﻿.signature-pad {
    width: 450px;

    .draw-container {
        display: flex;
        align-items: stretch;
        margin-bottom: $padding-base-vertical;

        .draw-area {
            height: 180px;
            overflow: hidden;
            flex: 11;
        }

        .controls {
            padding: $border-radius-base 0;
            flex: 1;
            display: flex;
            flex-direction: column;

            > *:first-child {
                /*margin-bottom: $padding-base-vertical;*/
            }

            .btn {
                border-left: 0;
                flex: 1;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }

    .btn-color {
        .fa {
            transition: opacity 0.25s ease-in-out;
            opacity: 0;
            color: white;
        }

        &.btn-color-active {
            .fa {
                opacity: 1;
            }
        }
    }

    .draw-area {
        position: relative;
        border: 1px solid gray;
        border-radius: $border-radius-base;
        cursor: crosshair;

        &::before, &::after {
            position: absolute;
            width: 90%;
            left: 5%;
            top: 80%;
            content: '';
            display: block;
            pointer-events: none;
        }

        &::after {
            height: 2px;
            background: black;
        }

        &::before {
            content: 'Signer her';
            text-align: center;
            padding-top: 6px;
        }
    }
}