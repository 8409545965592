

.btn_save, .btn_create {
	border:1px solid $green;
	background: $green;
	color:#fff !important;
	outline: none !important;
	&:hover {
		background: darken($green, 10%);
		border:1px solid darken($green, 10%);
	};
	&:focus {
		background: darken($green, 10%);
		border:1px solid #fff !important;
	}
}










/*  BUTTON WARNING  */

button.btn-warning {
	background: red !important;
	border-color: red !important;
	font-size: 14px;
	padding: 3px 6px;
	margin-bottom: 0 !important;
}
button.btn-warning .fa-stop::before {
    content: "\f1f8";
    color:#fff;
}
button.btn-warning:hover {
	background: firebrick !important;
	border-color: firebrick !important;
}




/*  BUTTON EDIT  */

button.btn-edit,
a.btn-edit {
	background: green;
	border-color: green;
	font-size: 14px;
	padding: 1px 5px;
	margin-bottom: 0 !important;
	color:white !important;
}
button.btn-edit:hover,
button.btn-edit:focus,
a.btn-edit:focus, 
a.btn-edit:hover  {
	background: darkgreen;
	border-color: darkgreen !important;
}
button.btn-edit .fa-arrow-right::before,
a.btn-edit .fa-arrow-right::before {
	color:#fff;
    content: "\f040";
}



/*  BUTTON HISTORY  */

button.btn-history,
a.btn-history {
	color:#fff;
	background: #065696;
	border-color: #065696;
	font-size: 14px;
	padding: 2.5px 9px !important;
	margin-bottom: 0 !important;
}
button.btn-history span,
a.btn-history span {
	padding-left: 4px;
}
button.btn-history:hover,
button.btn-history:focus,
a.btn-history:focus, 
a.btn-history:hover  {
	background: #034070;
	border-color: #034070 !important;
}
button.btn-history .fa-arrow-right::before,
a.btn-history .fa-arrow-right::before {
	color:#fff;
    content: "\f142";
}




/*  BUTTON FURTHER  */

button.btn-further,
a.btn-further {
	color:#fff;
	background: #065696;
	border-color: #065696;
	margin-bottom: 0 !important;
}
button.btn-further span,
a.btn-further span {
	padding-left: 4px;
}
button.btn-further:hover,
button.btn-further:focus,
a.btn-further:focus, 
a.btn-further:hover  {
	background: #034070;
	border-color: #034070 !important;
}
button.btn-further .fa-arrow-right::before,
a.btn-further .fa-arrow-right::before {
	color:#fff;
    /*content: "\f142";*/
}






// ADDITIONAL CLASSES ON DEFAULT BUTTONS

.btn-default {
	&.green {
		border:1px solid $green;
		background: $green;
		color:#fff;
		outline: none !important;
		&:hover {
			background: darken($green, 10%);
			border:1px solid darken($green, 10%);
		};
		&:focus {
			border:1px solid #fff !important;
		}
	}
	&.orange {
		border:1px solid $orange;
		background: $orange;
		color:#fff;
		outline: none !important;
		&:hover {
			border:1px solid darken($orange, 10%);
			background: darken($orange, 10%);
		};
		&:focus {
			border:1px solid #fff !important;
		}
	}
	&.blue {
		border:1px solid $blue;
		background: $blue;
		color:#fff;
		outline: none !important;
		&:hover {
			border:1px solid darken($blue, 10%);
			background: darken($blue, 10%);
		};
		&:focus {
			border:1px solid #fff !important;
		}
	}
	&.grey {
		$dark_grey: darken(#fff, 40%);
		border:1px solid $dark_grey;
		background: $dark_grey;
		color:#fff;
		outline: none !important;
		&:hover {
			border:1px solid darken($dark_grey, 10%);
			background: darken($dark_grey, 10%);
		};
		&:focus {
			border:1px solid #fff !important;
		}
	}
	&.float_right {
		float: right;
	}
}




.fa-spin {
	// transform-origin: 65% 50% !important;
}

