﻿.wrapper .dashboard.operations {
    margin-left: -15px;
    margin-right: -15px;
}

.dashboard-container {
    .header {
        top: 0;
    }

    > div {
        margin-top: 59px;
    }
}

.dashboard.operations {
    position: relative;
    min-height: 1021px;
    > .map {
        position: absolute;
        left: 0;
        width: 25%;
        top: 0;
        bottom: 0;
        .angular-google-map-container {
            height: 1021px;
        }
    }

    .list {
        position: absolute;
        left: 25%;
        width: 75%;
        top: 0;
        bottom: 0;
        padding: 15px;

        .map {
            margin-top: -15px;
            margin-right: -15px;
            height: 120px;

            .angular-google-map-container { height: 125px; }
        }
    }

    .time-panel {
        margin-top: 24px;
        margin-left: 0;

        &-date {
            font-size: 85%;
            color: gray;
        }

        .ellipsis {
            width:100%;
            display: table;
            table-layout: fixed;
            margin-bottom: -6px;

            & > span {
                display: table-cell;
                width: 100%;
                display:inline-block;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

    .dock > h3 {
        text-align: center;
    }

    .operation {
        position: relative;
        .status {
            position: absolute;
            background: pink;
            box-shadow: 0px 3px 2px #aab2bd;
            left: 0;
            top: 10px;
            height: 95px;
            width: 40px;
            color: white;


            &.warning {
                background: $brand-warning;
            }
            &.success {
                background: $brand-success;
            }

            .fa {
                position: absolute;
                left: 10px;
                top: 36px;
                font-size: 2em;
            }
        }

        .call-panel {
            margin-left: 40px;
        }
    }
}

.dashboard-comment {
    display: table;

    &-wrapper {
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        font-size: 10px;
        height: 8.5em;
    }
    
    &-text {
        text-align: left;
        width: 144%;
        margin-left: -28%;
        max-height: 8.5em;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.operations-map-label {
    color: white;
    width: 200px;
    text-align: center;
    text-shadow:0 0 2px black;
    text-shadow:
        1px 0 1px black,
        -1px 0 1px black,
        0px 1px 1px black,
        0px -1px 1px black;
    
}
