
.content-panel.snarveg_mottak {
	h1 {
		display: inline-block;
	}
}


.modal-body.snarveg_mottak {
	button.green, button.orange {
		margin-left: 8px;
	}
}