





html {
	overflow-x: hidden;
	overflow-y: auto;
    position: relative;
    min-height:100%;
}



body {
	width:100%;
    min-height:100%;
    position: relative;
}

#container {
	overflow: hidden;
}





// .modal-backdrop {
// 	position: fixed;
// 	top: 0;
// 	right: 0;
// 	bottom: 0;
// 	left: 0;
// 	z-index: 1040;
// 	background-color: #000;
// 	opacity: 0;
// 	transition: opacity 0.5s;
// 	&.fade.in {
// 		// opacity: 1;
// 	}
// }
// // .modal-backdrop.fade {
// // 	opacity: 0;
// // 	filter: alpha(opacity=0);
// // }