﻿.visitor-panel h1 {
    text-align: center;
}

.visitors-empty {
    text-align: center;
}

.visitor-panel.content-panel {
    box-shadow: 0px 4px 4px fade(black, 35%),
         0 1px 1px fade(black, 50%);
}
