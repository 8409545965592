﻿.show-previous-toggle {
    float: right;
    border: none;
    -webkit-appearance: none;
    background: none;

    color: $gray-light;
    outline: none;
    transition: color 0.25s ease-out;

    &:hover {
        color: $gray;
    }
    &.toggled {
        color: black;
    }
}

.diff {
    .previous {
        del { text-decoration: line-through; }
        color: $gray-light;
    }

    &-reservation{
        color:$gray-light;
        text-decoration: line-through;
    }
}
