﻿$line-size: 6px;
$dot-size: $font-size-large + $padding-base-vertical * 2;
$arrow-size: 10px;

.timeline {
    margin-top: $padding-base-vertical * 2 + ($dot-size / 2);
    position: relative;
    text-align: center;

    &.full-width:before {
        // line background
        z-index: 0;
        content: '';
        position: absolute;
        background: $brand-primary !important;  // overriding print styles must be !important
    }

    .event {
        display: inline-block;
        position: relative;
        z-index: 1000;
        margin: ($padding-base-vertical * 2 + $arrow-size) $padding-base-horizontal $padding-base-vertical;
    }

    .content {
        text-align: left;
        position: relative;
        background: white;
        border: 1px solid $gray-lighter;
        border-radius: 4px;
        box-shadow: 0px 3px 2px #aab2bd;
        > * {
            overflow: hidden;
            padding: $padding-base-vertical $padding-base-horizontal;
        }
    }

    .map {
        margin: -$padding-base-vertical -$padding-base-horizontal $padding-base-vertical;
    }

    .dot {
        position: absolute;
        content: '';
        display: block;
        border-radius: 100%;
        text-align: center;
        width: $dot-size;
        height: $dot-size;
        background: $brand-primary !important; // overriding print styles must be !important
        box-shadow: inset 0px 3px 2px rgba(0,0,0,.25);

        .fa {
            color: white !important;  // overriding print styles must be !important
            text-align: center;
            line-height: $dot-size;

            &:before { color: white !important; }  // overriding print styles must be !important
        }
    }

    .timestamp {
        position: absolute;
        font-size: $font-size-small;
        text-align: left;
    }

    .year {
        vertical-align: top;
        display: inline-block;
        color: white !important;  // overriding print styles must be !important
        background: $brand-primary !important;  // overriding print styles must be !important
        color: white;
        padding: $padding-base-vertical $padding-base-horizontal;
        font-size: $font-size-large;
        line-height: 1em;
        border-radius: 4px;
        box-shadow: inset 0 3px 2px rgba(0,0,0,0.25);
        z-index: 1000;
        position: relative; // make z-index work
    }
}

.timeline-horizontal {
    display: block;

    &.full-width:before {
        left: 0;
        right: 0;
        top: $dot-size / 2;
        height: $line-size;
        box-shadow: inset 0 3px 2px rgba(0,0,0,0.25);
    }

    .span:before {
        top: $dot-size / 2;
        left: 0;
        right: 0;
        height: $line-size;
        box-shadow: inset 0 3px 2px rgba(0,0,0,0.25);
    }

    .scroller {
        padding-top: $dot-size / 2;
        overflow-x: auto;
        overflow-y: visible;
        white-space: nowrap;
    }

    @media print {
        .scroller {
            white-space: normal;
        }

        .event {
            margin-bottom: 20px;
        }
    }

    .yearGroup {
        margin-left: $padding-base-horizontal;
        margin-right: $padding-base-horizontal;

        + .yearGroup {
            margin-left: 0;
        }
    }

    .event, .year {
        vertical-align: top;
    }

    .year {
       margin-top: -($dot-size - $line-size) / 2;
    }

    .content {
        // .arrow($arrow-size, white, top, $padding-base-horizontal + $arrow-size, 1px, $gray-lighter);
    }

    .dot {
        margin-left: $padding-base-horizontal + $arrow-size - ($dot-size / 2) + 1px;
        margin-top: -($dot-size + ($line-size / 2));
    }

    .timestamp {
        left: $dot-size + $padding-base-horizontal;
        top: -($dot-size + $line-size) - 4px;
        line-height: $font-size-small + $line-size + 4px;

        > * {
            display: block;
        }
    }
}

.timeline-vertical {
    display: block;
    text-align: center;
    position: relative;

    .span {
        position: relative;
        margin-bottom: $padding-large-vertical * 4;
    }

    .span:before {
        z-index: 0;
        content: '';
        position: absolute;
        left: 50%;
        top: 0;
        bottom: 0;
        width: $line-size;
        margin-left: -$line-size / 2;
        background: $brand-primary !important;  // overriding print styles must be !important
    }

    .content {
        // .arrow($arrow-size, white, left, $padding-base-horizontal + $arrow-size, 1px, $gray-lighter);
    }

    .event {
        display: block;
        $vertical-dot-distance: -($dot-size + $line-size) / 2 - $padding-base-horizontal + 2;

        padding-top: $font-size-small + 2;

        .content {
            margin: 0 $padding-base-horizontal 0;
        }

        width: 40%;
        left: 50%;

        .dot {
            margin-top: $padding-base-horizontal + $arrow-size - ($dot-size / 2) + 1px;
            margin-left: $vertical-dot-distance;
        }

        .timestamp {
            top: 0;
            margin-left: $padding-base-horizontal * 2;
        }

        &.pulled:last-child .dot {
            bottom: -2px;
        }

        @media (min-width: $screen-md-min) {
            &.pulled {
                margin-left: -14px;
                right: 50%;
                left: 10%;
                text-align: right;

                .dot {
                    right: 0;
                    margin-left: 0;
                    margin-right: $vertical-dot-distance;
                }

                .content {
          //          .arrow($arrow-size, white, right, $padding-base-horizontal + $arrow-size, 1px, $gray-lighter);
                }
            }
        }
    }

    @media (max-width: $screen-sm-max) {
        text-align: left;

        .span:before {
            left: $dot-size;
        }

        .event {
            left: $dot-size;
            margin-right: $dot-size;
            width: inherit;
        }
    }

}

// .arrow(
//   $size,
//   $color,
//   $direction,
//   $offset,
//   $border-size: 0,
//   $border-color: inherit) {
//   position: relative;
//   &:after,
//   &:before {
//     content: "";
//     position: absolute;
//     width: 0;
//     height: 0;
//   }
// }

// .arrow($size, $color, $direction, $offset, $border-size: 0, $border-color: inherit) when ($direction = top) {
//  $m-size: $size + ($border-size*2);

//   &:after {
//     bottom: 100%;
//     left: $offset;
//     margin-left: -$size;
//     border: 0;
//     border-left: $size solid transparent;
//     border-right: $size solid transparent;
//     border-bottom: $size solid $color;
//   }

//   &:before {
//     bottom: 100%;
//     left: $offset;
//     margin-left: -$m-size;
//     border: 0;
//     border-left: $m-size solid transparent;
//     border-right: $m-size solid transparent;
//     border-bottom: $m-size solid;
//     border-bottom-color: $border-color;
//   }
// }

// .arrow($size, $color, $direction, $offset, $border-size: 0, $border-color: inherit) when ($direction = bottom) {
//   $m-size: $size + ($border-size*2);

//   &:after {
//     top: 100%;
//     left: $offset;
//     margin-left: -$size;
//     border: 0;
//     border-left: $size solid transparent;
//     border-right: $size solid transparent;
//     border-top: $size solid $color;
//   }

//   &:before {
//     top: 100%;
//     left: $offset;
//     margin-left: -$m-size;
//     border: 0;
//     border-left: $m-size solid transparent;
//     border-right: $m-size solid transparent;
//     border-top: $m-size solid;
//     border-top-color: $border-color;
//   }
// }

// .arrow($size, $color, $direction, $offset, $border-size: 0, $border-color: inherit) when ($direction = right) {
//   $m-size: $size + ($border-size*2);

//   &:after {
//     left: 100%;
//     top: $offset;
//     margin-top: -$size;
//     border: 0;
//     border-top: $size solid transparent;
//     border-bottom: $size solid transparent;
//     border-left: $size solid $color;
//   }
//   &:before {
//     left: 100%;
//     top: $offset;
//     margin-top: -$m-size;
//     border: 0;
//     border-top: $m-size solid transparent;
//     border-bottom: $m-size solid transparent;
//     border-left: $m-size solid;
//     border-left-color: $border-color;
//   }
// }

// .arrow($size, $color, $direction, $offset, $border-size: 0, $border-color: inherit) when ($direction = left) {
//   $m-size: $size + ($border-size*2);

//   &:after {
//     right: 100%;
//     top: $offset;
//     margin-top: -$size;
//     border: 0;
//     border-top: $size solid transparent;
//     border-bottom: $size solid transparent;
//     border-right: $size solid $color;
//   }
//   &:before {
//     right: 100%;
//     top: $offset;
//     margin-top: -$m-size;
//     border: 0;
//     border-top: $m-size solid transparent;
//     border-bottom: $m-size solid transparent;
//     border-right: $m-size solid;
//     border-right-color: $border-color;
//   }
// }