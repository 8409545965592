
// DEFAULTS DIVS
// --------------------------------------------------------

// .format {
//     @import "4__format";
// }

// .noscroll { 
//     overflow: hidden; 
//     header#header {
//         animation-duration: 5s!important;
//     }
// }

// .no-href { 
//     &, & * {
//         cursor: default !important; 
//     }
// }

// .flexible .flex {
//     display: inline-block;
//     width: 100%;
// }


// .bloc-container {
//     height:100%;
//     display: flex;
//     align-items: center;
// }

// .bloc {
//     display: flex;
//     align-items:center;
//     height: 100%;
//     .bloc-inside {
//         display: inline-block;
//         width: 100%;
//     }
// }

// .frame {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     img {
//         width: 100%;
//     }
// }

// .image_container, .logo_container, .icon_container {
//     display: inline-block;
//     position: relative;
//     height: 100%;
//     img {
//         width: 100%;
//         height: auto;
//     }
// }
// .image {
//     background-position:50% 50%;
//     background-size:cover;
//     width:100%;
//     height:100%;
//     position: absolute;
//     top: 0;
//     left: 0;
// }

// .contain {
//     background-size: contain;
//     background-position:50% 50%;
// }

.overlay {
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
}

// .icon {
//     display: inline-block;
//     width: 100%;
//     height: 100%;
// }

// svg {
//     height:100%;
//     width:100%;
// }



// .abs {
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     width:100%;
//     height:100%;
// }


// .preload {display:none!important;}


// .break-word {
//     overflow-wrap: break-word;
//     display: block;
// }

// .clearfix::before,
// .clearfix::after {
//     content:"";
//     display: block;
// }
// .clearfix::after {
//     clear:both
// }





// *[back-img], *[back-asset] {
//     opacity: 0;
//     transition: opacity 1s;
// }

// *[my-parallax] {
//     opacity: 0;
// }







// // CACHES
// // -----------------------------------------


// .cache-left {
//     position: relative;
//     &::before {
//         content:"";
//         display: inline-block;
//         height:100%;
//         width:2000%;
//         position: absolute;
//         right:100%;
//         top:0;
//         background:inherit;
//     }
// }

// .cache-right {
//     position: relative;
//     &::before {
//         content:"";
//         display: inline-block;
//         height:100%;
//         width:2000%;
//         position: absolute;
//         left:100%;
//         top:0;
//         background:inherit;
//     }
// }

// .cache-bottom-left, .cache-left-bottom {
//     position: relative;
//     &::after {
//         content:"";
//         display: inline-block;
//         height:10000%;
//         width:2000%;
//         position: absolute;
//         right:0;
//         top:100%;
//         background:inherit;
//     }
// }

// .cache-bottom-and-left, .cache-left-and-bottom {
//     position: relative;
//     &::before {
//         content:"";
//         display: inline-block;
//         height:10000%;
//         width:2000%;
//         position: absolute;
//         right:0;
//         top:0%;
//         background:inherit;
//     }
// }

// .cache-bottom {
//     position: relative;
//     &::after {
//         content:"";
//         display: inline-block;
//         height:10000%;
//         width:100%;
//         position: absolute;
//         left:0;
//         top:100%;
//         background:inherit;
//     }
// }

// .cache-bottom-right, .cache-right-bottom  {
//     position: relative;
//     &::after {
//         content:"";
//         display: inline-block;
//         height:10000%;
//         width:2000%;
//         position: absolute;
//         left:0%;
//         top:100%;
//         background:inherit;
//     }
// }



// // ON - OFF
// // --------------------------------------------------------


// .block {
//     display: block!important;
// }

// .laptop-on, .l-on {
//     display:none!important;
//     @include brk($l){display:inline-block!important;}
// }

// .laptop-off, .l-off {
//     @include brk($l){display:none!important;}
// }

// .tablet-on, .t-on {
//     display:none!important;
//     @include brk($t){display:inline-block!important;}
// }

// .tablet-off, .t-off {
//     @include brk($t){display:none!important;}
// }

// .td-on {
//     display:none!important;
//     @include brk($td){display:inline-block!important;}
// }

// .td-off {
//     @include brk($td){display:none!important;}
// }

// .tp-on {
//     display:none!important;
//     @include brk($tp){display:inline-block!important;}
// }
// .tp-off {
//     @include brk($tp){display:none!important;}
// }

// .mobile-on, .m-on {
//     display:none!important;
//     @include brk($m){display:inline-block!important;}
// }
// .mobile-off, .m-off {
//     @include brk($m){display:none!important;}
// }

// .sm-on {
//     display:none!important;
//     @include brk($sm){display:inline-block!important;}
// }
// .sm-off {
//     @include brk($sm){display:none!important;}
// }







// // PADDINGS
// // --------------------------------------------------------


// .tablet-paddings {
//     @include brk($t) {
//         padding-left: $padding-tablet !important;
//         padding-right: $padding-tablet !important;
//     }
//     @include brk($m) {
//         padding-left: $padding-mobile !important;
//         padding-right: $padding-mobile !important;
//     }
// }

// .mobile-paddings {
//     @include brk($m) {
//         padding-left: $padding-mobile !important;
//         padding-right: $padding-mobile !important;
//     }
// }

// .tablet-paddings-off {
//     @include brk($t) {
//         padding-left:0;
//         padding-right:0;
//     }
// }

// .paddings {
//     padding: 0 $padding-desktop;
//     @include brk($t){padding-left: $padding-tablet; padding-right: $padding-tablet;}
//     @include brk($m){padding-left: $padding-mobile; padding-right: $padding-mobile;}
// }








// // TRANSITIONS OUT
// // --------------------------------------------------------



// * [outFade].go {
//     transition: opacity 0.5s, transform 0.5s;
//     opacity: 0;
//     transform: translate3d(0,5px,0);
// }

// * [outLeft].go {
//     transition: opacity 0.5s, transform 0.5s;
//     opacity: 0;
//     transform: translate3d(-10px,0px,0);
// }

// * [outRight].go {
//     transition: opacity 0.5s, transform 0.5s;
//     opacity: 0;
//     transform: translate3d(10px,0px,0);
// }




