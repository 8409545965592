



.content-panel.forsendelse.mt {
	// padding-top: 0px;

	.table-striped-container {
		padding-top: 5px;
	}
}



.app-forsendelse-window .modal-dialog {
    width: 100%;
    max-width: 90%;
    @include brk($m) {
    	max-width: calc(100% - 20px);
    }


    .modal-content {
	    width: 100%;
	    // overflow-x: scroll;

	    .attachment_container {
	    	width: 100%;
	    	padding: 0 20px;

	    	.attachment-panel {
	    		box-shadow: 1px 1px 3px transparentize(#000, 0.7);
	    	}
	    }

	    label {
	    	font-size: 13px;
	    	font-weight: bold;
	    }
	    
	}


	form {
		margin-top: 15px;
		padding-top: 30px;
		border-top: 1px solid transparentize(#000, 0.9);

		.row {
			margin: 0;
			>ul {
				padding-left: 20px;
				padding-bottom: 20px;
				border-bottom: 1px solid transparentize(#000, 0.9);
				margin-bottom: 25px;
				@include brk($m) {
					padding-left: 0;
				}
				>li {
					display: inline-block;
					width: 100%;
					margin-bottom: 5px;
					>label {
						width: 260px;
						display: inline-block;
						text-align: right;
						padding-top: 7px;
						padding-right: 10px;
						float: left;
						@include brk($td) {
							width: 220px;
						}
						@include brk($m) {
							width: 100%;
							text-align: left;
						}
					}
					.select_container {
						width: 30%;
						display: inline-block;
						float: left;
						position: relative;
						@include brk($td) {
							width: 22%;
						}
						@include brk($m) {
							width: 100%;
						}
						.overlay {
							display: none;
							&.cover {
								display: inline-block;
								background: transparentize(#fff, 0);
								font-size: 17px;
								color: lighten($green, 40%);
								@extend .fas;
								@extend .fa-plus;
								&::before {
									display: inline-block;
									transform: translate(5px, 9px);
								};
							}
							&.disable {
								display: inline-block;
								background: transparentize(#fff, 0.5);
							}
						}
					}
					.checkboxs_container,
					.edit_container {
						margin-bottom: 0px;
						float: left;
						display: inline-block;
						@include brk($tp) {
							// margin-left: 200px;
						}
						span {
							text-align: left;
						}
					}
					.checkboxs_container {
						padding-left: 20px;
						user-select: none;
						@include brk($m) {
							width: 100%;
							// text-align: center;
							padding-left: 16px;
						}

						&.kunde {
							// display: none !important;
						}
						span {
							margin-right: 0px;
							position: relative;
							width: 140px;
							display: inline-block;
							// cursor: pointer;
							user-select: none;
							@include brk($td) {
								width: 125px;
								font-size: 13px;
							}
							@include brk($m) {
								width: auto;
								&:first-child {
									margin-right: 20px;
								}
							}
							label {
								padding: 8px 20px 5px;
								padding-left: 0px;
								margin-bottom: 0;
								transition: color 0.3s;
								&:hover {
									color:#000;
								};
							}
							
							label, input {
								cursor: pointer;
								user-select: none;
								@include brk($td) {
									font-size: 12px;
								}
							}

							&.disabled {
								&::after {
									content:"";
									background: transparentize(#fff, 0.4);
									display: inline-block;
									cursor: default;
									width: 100%;
									height: 100%;
									position: absolute;
									top: 0;
									left: 0;
									transition: opacity 0.5s;
									opacity: 1;
									animation: fade 0.2s;
								};
							}
						}
						input {
							display: none;
							&:checked + .yoga_switch {
								background: $blue;
								&::after {
									transform: translateX(12px);
								};
							}
						}
						.yoga_switch {
							width: 32px;
							height: 20px;
							background: darken(#fff, 10%);
							// border: 1px solid darken(#fff, 30%);
							border-radius: 10px;
							float: left;
							margin-right: 5px;
							padding: 2.5px;
							overflow: hidden;
							&::after {
								content:"";
								display: inline-block;
								height: 15px;
								width: 15px;
								border-radius:50%;
								background: #fff;
								transition: transform 0.2s;
								box-shadow: 1px 1px 10px transparentize(#000, 0.6);

							};
						}
					}
					.edit_container {
						margin-top: 10px;
						margin-bottom: 10px;
						padding: 10px;
						clear: both;
						margin-left: 260px;
						width: calc(100% - 260px);
						animation: fade 0.5s;
						border-bottom-left-radius: 15px;
						@include brk($td) {
							margin-left: 220px;
						}
						@include brk($m) {
							margin-left: 0px;
							width: 100%;
						}
						&.green {
							background: lighten($green, 50%);
						}
						&.blue {
							background: lighten($blue, 50%);
						}
						label {
							width: 110px;
							display: inline-block;
							text-align: right;
							padding-top: 11px;
							padding-right: 10px;
							float: left;
						}
						input {
							width: 26%;
							display: inline-block;
							float: left;
							min-width: 200px;
							@include brk($m) {
								width: 100%;
							}
						}
						ul {
							padding-left: 0;
							// width: auto;
							// display: inline-block;
							li {
								display: inline-block;
								width: 100%;
								line-height: 1;
							}
						}
					}
				}
			}
		}
	}


	form .row.row-1 {
		span {
			
		}
	}















	td input[type="date"] {
		line-height: 1.4;
		padding: 4px 7px;
		// border-radius: 3px;
	}

	td input[type="text"],
	td select {
		padding: 6px 7px;
	}

	table.table-striped td.td_ra226 {
		min-width: 60px;
	}
	table.table-striped td.td_ra228 {
		min-width: 60px;
	}
	table.table-striped td.td_deponiKunde {
		min-width: 120px;	
	}



	td input,
	td select {
		&.ng-empty,
		&.is_zero  {
			border-radius: 4px;
			border:2px solid $red;
		}
	}



	.table-striped-container {
		margin-top: 20px;
		margin-bottom: 40px;
		padding-bottom: 20px;
		.table.table-striped {
			margin-bottom: 0;
		}
		label {
			// display: none;
		}

		.action_row {
			th {
				background: white;
				border:none;
			}
			th:not(.action_cell) {
				// display: none;

			}
			label {
				display: block !important;
				text-align: center;
			}
		}
		.head_row th {
			display: none !important;
		}
	}
}

