



aside#sidebar {
	position: fixed;
	overflow-x: hidden;
	overflow-y: scroll;
    max-height: 100%;
    max-width: 210px;
    width: 210px;
    padding-right: 0 !important;
    @media only screen and  (min-width: 769px){
    	display: inline-block !important;
    }
    @media print {
    	overflow-y: hidden !important;
    }
    ul.sidebar-menu {
		padding-bottom: 40px !important;
		li {
			margin-bottom: 1px !important;
			margin-left: 0 !important;
			margin-right: 0 !important;
			a {
				color: #aeb2b7;
				text-decoration: none;
				display: block;
				outline: none;
				
				font-size: 14px !important;
				transition: all 0s !important;
				padding: 9px 0 8px 10px !important;
				line-height: 1 !important;
				height: auto !important;
				user-select: none;
				span {
					line-height: 1 !important;
				}
				i {
				    font-size: 15px;
				    padding-right: 6px;
				    text-align: center;
				    width: 25px;
				}
				&.active,
				&:hover,
				&:focus {
					background: #065696;
					color: #fff;
					display: block;
					-webkit-transition: all 0.3s ease;
					-moz-transition: all 0.3s ease;
					-o-transition: all 0.3s ease;
					-ms-transition: all 0.3s ease;
					transition: all 0.3s ease;
					i {
				  		color: #fff;
					}
				};
			}
			li a {
				padding: 8px 0 7px 10px !important;
			}
		}



		// li.sub-menu >a {
		// 	@extend .fas;
		// 	@extend .fa-chevron-right;
		// }


		li.sub-menu >a::after {
			content:"›";
			position: absolute;
		    right: 10px;
		    top: 8px;
		    transition: transform 0.3s;
		}
		li.sub-menu >a.active::after {
			transform: rotate(90deg);
		}
		li.sub-menu > ul > li {
			padding-left: 28px !important;
			> a > i {
				width: 28px;
				text-align: center;
			}
		}
	}
	span.icon {
		width: 27px;
	    text-align: center;
	    padding-right: 5px;
	    font-weight: bold;
	}

}

