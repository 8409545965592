

/*  GENERAL  */


h1, .h1 {
    font-size: 36px;
    @include brk($m) {
        font-size: 30px;
    }
}

@keyframes fade {
    0% {opacity:0}
    100% {opacity:1}
}


.wrapper {
	padding-top: 15px !important;
}

*[ng-if]:not(.ng-scope) {
	display: none !important;
}


span.label {
	padding: .4em .6em .3em;
}

header.header {
	position: fixed;
	top: 0;
}

header button.btn-search {
	text-transform: uppercase;
	font-size: 14px;
	font-weight: 700;
}


td.td-buttons button.btn-success {
	margin-bottom: 0 !important;
}

thead th {
	font-weight: 900;
}








@media only screen and (max-device-width: 480px) { 


	/*  GENERAL  */


    .btn-content-panel {
    	margin-right: 15px !important;
    }

    .col-sm-2 {
    	margin-bottom: 12px;
    }

    .content-panel h1 {
    	display: inline-block;
    }

    .empty-state .fa-search {
    	font-size: 2em;
    }

    .empty-state h1 {
    	font-size: 24px;
    	padding-left: 10px;
    }

    h1.global-title {
    	font-size: 24px !important;
    	width: 100%;
    	margin-left: 0 !important;
    	margin-right: 0 !important;
    }




}




.datetimepicker-date {
    i {
        padding-top: 9px;
    }
}





