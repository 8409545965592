﻿.item-view {
    @media (max-width: $screen-sm-max) {
        .right-divider {
            border-right: none;
        }
    }

    .map-container {
        padding-bottom: 0;
        padding-top: 0;
    }

    .map-container-flush {
        margin-top: -15px;
        margin-bottom: -5px;
        margin-right: -15px;
    }

    .information {
        min-height: 135px;
    }

    .profile-pic {
        float: right;
    }
}

.dm-icon {
    background: $brand-primary !important;
    cursor: default;

    &:before { color: white !important; background: transparent !important; }
}


.row.properties {
    text-align: center;
    white-space: nowrap;

    h4 {
        margin-left: 0;
        text-overflow: ellipsis;
        overflow: hidden;
    }
}

.item-index {
    .xs-only { display: none; }
    @media screen and (max-width: $screen-xs-max) {
        .xs-only {
            display: inherit !important;
        }

        table, thead, tbody, th, td {
            display: block;
        }
        tr {
            display: flex;
            flex-direction: column;
        }
        // Hide table headers (but not display: none;, for accessibility)
        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        .xs-priority {
            order: -1;
            font-weight: bold;
        }

        tr {
            border-bottom: $padding-base-vertical solid white;
        }

        .table td {
            // Behave  like a "row"
            border: none;
            border-bottom: 1px solid white;
            position: relative;
            padding-left: 50% !important;
            min-height: 18px;
        }

        td:before {
            content: attr(title);
            // Now like a table header
            position: absolute;
            // Top/left values mimic padding
            top: $padding-base-vertical;
            left: $padding-base-vertical;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
        }
    }
}

.button-row {
    .btn-container { display: inline-block; }

    @media (max-width: $screen-xs-max) {
        margin: ($grid-gutter-width / 2) (-$grid-gutter-width / 2);

        .btn-container {
            // .make-xs-column(6);

            .btn, .btn-group { display: block; width: 100%; }
        }
    }
}
