



/*  FABRICATION DATA */

.content-panel.fabrication-data {
	padding-top: 0;
	background: none;
	box-shadow: none;
	.title-container {
		background: #fff;
	    margin-bottom: 20px;
	    padding: 15px 40px 30px;
	    box-shadow: 0px 3px 2px #aab2bd;
	    display: inline-block;
	    width: 100%;
		@media only screen and (max-width: 600px) { 
			padding: 15px 20px 30px;
		}
		.title {
			margin-bottom: 20px;
		}
		select {
		    padding:5px 10px;
		    width: 100%;
		    height: 32px;
		    max-width: 150px;
		    &:focus {
				border: 2px solid #ffbc18;
			    outline: none !important;
			}
		}
	}
	form.form-clean-layout .table-striped-container {
		width: 120%;
		max-width: 120%;
		overflow: hidden;
		margin-bottom: 10px;
		@media only screen and (max-width: 600px) { 
			overflow-x: scroll;
			width: 100%;
			max-width: 100%;
		}
		table {
			padding-top: 30px;
			padding-bottom: 15px;
			padding-left: 5px;
			padding-right: 5px;
			display: inline-block;
			width: 100%;
			p {
				padding: 5px 5px;
			}


		}
	}
}





/*  FABRICATION DATA BULKVARER */

.content-panel.fabrication-data.bulkvarer {
	background: #fff;
	box-shadow: 0px 3px 2px #aab2bd;
	.form-clean-layout-container {
		box-shadow: none;
	}
	.title-container {
		box-shadow: none;
		padding-bottom: 0;
		margin-bottom: 0;
		border: 1px solid #eee;
	}
}
