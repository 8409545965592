﻿.time-panel-column {
    display: inline-block;
    vertical-align: top;
    position: relative;

    > .fa {
        position: absolute;
        left: 0;
        top: 6px;
    }

    .time-panel {
        margin-left: 36px;
    }
}

.time-panel {
    margin-right: $padding-large-horizontal;
    padding-bottom: 18px;

    p {
        font-size: 12px;
        margin-bottom: 0;
    }
    h3 {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 18px;
        font-weight: bold;
    }

    h5 {
        margin-top: 0;
        font-size: 12px;
        margin-bottom: -12px;
    }

    &.active {
        p, h3 {
        color: black;
        }
    }
}

.plan-comment {
    margin: $grid-gutter-width / 2 0;
    position: relative;
    vertical-align: top;

    header {
        font-size: 12px;
        margin-left: 36px;
    }
    .comment {
        font-size: 18px;
        font-weight: bold;
        margin-left: 36px;

        white-space: pre-wrap;
    }

    > .fa {
        position: absolute;
        left: 0;
        top: 6px;
    }
}

.call-panel {
    position: relative;

    .btn-expand {
        position: absolute;
        right: $grid-gutter-width / 2;
        top: $grid-gutter-width / 2;
        z-index: 10000;
    }
}

.wizard-panel .btn.pull-right {
    margin-top: -34px;
    z-index: 1000;
}

.wizard-panel .checkbox {
    max-width: 50%;
}

.attachment-panel {
    background: white;
    /*background: $brand-primary;
    color: white;*/
    text-align: center;
    height: auto;

    margin-bottom: $grid-gutter-width;

    h4 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre;
        margin: 0 20px;
    }

    .fa {
        margin: $padding-large-vertical * 2;
    }

    .actions {
        padding-bottom: $padding-large-vertical * 2;
        margin-left: $padding-large-horizontal;
        margin-right: $padding-large-horizontal;
    }

    &.uploading {
        .progress { margin-bottom: 7px; }
        p { margin-bottom: 17px; }
    }
}

.fa-ellipsis-ext-right {
    float: right;
    display: inline-block;
    margin-top: 1em; 
    margin-right: 0.1em;
    padding: 1em;
}

.fa-close-ext-larger {
    color: red;
    font-size: 1.35em;
    vertical-align: middle;
    width: 0.75em;
    margin-left: -0.05em;
}

@media print {
    .wrapper { display: block; }

    *, *:before, *:after {
        background: inherit !important;
        color: inherit !important;
    }

    .plan {
        position: relative;
    }

    .plan-panel, .call-panel, .timeline {
        -webkit-print-color-adjust: exact;
        page-break-inside: avoid;
        position: relative; // required for page-break-inside to work in Chrome
        /*page-break-after: always;*/
    }

    .call-details.ng-hide {
        display: block !important;
    }

    .plan-timeline, .plan-actions, .plan-attachments { display: none; }

}

