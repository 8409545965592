

.modal-body.mottaks_oversikt3 {
	.buttons_top_container {
		display: inline-block;
		width: 100%;
		margin-bottom: 20px;
		button.green, button.orange {
			margin-left: 8px;
		}
	}
}