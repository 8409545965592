



.modal-body.gate_system_access {
	.well {
		label {
			user-select: none;
			cursor: pointer;
		}
		.toggle_all_zones_container {
			margin-bottom: 10px;
		}
	}
}