


$selectize_text_color: #000;
$selectize_background_color: #eee;


// SELECTIZE  --------------------------------

.selectize-control {
    user-select: none;
    // width:calc(100% - 115px);
    // float: right;
    @include brk($m) {
        // float: none;
        // width:100%;
    }
    &.multi {
        .item {
            cursor:default;
        }
    }
    .selectize-input {
        height:auto;
        height:40px;
        background:$selectize_background_color;
        padding:0 !important;
        position: relative;
        border-radius:0px;
        cursor:pointer;
        &.focus {
            & + .selectize-dropdown {
                z-index: 100;
            }
        }
        &.full {
            outline: 2px solid $orange;
        }
        .item {
            height: 100%;
            user-select:none;
            align-items: center;
            color:$selectize_text_color;
            font-size:14px;
            line-height: 1.2;
            display: inline-block;
            padding:13px 5px 18px 15px;
            @include brk($m) {
                font-size:13px;
            }
            &:hover {
                // background:darken(#4f009d, 10%);
            }
            a.remove {
                background:darken(#4f009d, 10%);
                width: 20px;
                height: 20px;
                border-radius: 50%;
                text-align: center;
                color:$selectize_text_color;
                margin-right: 8px;
                float: left;
                // animation: scale 0.5s;
                &:hover {
                    background: #fff;
                    color: darken(#000, 10%);;
                };
            }
        }

        // remove button (install plugin with config.plugins: ['remove_button'])
        a.remove-single {
            position: absolute;
            top: -1.5px;
            left: 100%;
            // width: 35px;
            display: flex;
            align-items: center;
            padding-left: 5px;
            height: calc(100% + 3px);
            color:#fff;
            background: $orange;
            color: transparent;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            &:hover {
                background: darken($orange, 10%);
            };
            &::before {
                font-family: 'Font Awesome 5 Free';
                margin-left: 6px;
                content: "\f05e";
                color:#fff;
                font-size: 14px;
                font-weight: 900;
            };
            &::after {
                margin-left: 0px;
                margin-right: 11px;
                padding-top: 1px;
                font-size: 12px;
                content: "nullstille";
                color:#fff;
                font-size: 13px;
                font-weight: 700;
            };
        }
        input {
            // display:none !important;
            position: absolute !important;
            top: 0;
            left: 0;
            background:none;
            border:none;
            outline:none;
            color:$selectize_text_color;
            width: 100%!important;
            display: inline-block;
            height: 100%;
            padding-left: 15px;
            cursor:pointer;
            padding-right: 50px !important;
            &::placeholder {
                transition: color 0.3s;
                color: transparentize($selectize_text_color, 0.1) !important;
            }
            &:hover::placeholder {
                color: transparentize($selectize_text_color, 0) !important;
            }
            &:focus {
                &::placeholder { opacity: 0.35!important; }
            }
        }
        &.has-items {
            input {display:none!important}
        }
        &::after {
            content:"";
            height:16px;
            width:16px;
            border:none;
            margin-top: 0;
            display:inline-block;
            position: absolute;
            top:50%;
            right:15px;
            opacity: 0.5;
            transform: translate(0,-50%);
            background-position: 50% 50% !important;
            background:url('../assets/images/select-dropdown.svg');
            // background:url('../assets/images/select-dropdown-small.svg');
            // background:green;
        }
    }
    .selectize-dropdown {
        overflow: hidden;
        border-radius:0px;
        margin-top: 0;
        position: absolute;
        border: none !important;
        top: 0;
        left: 0;
        box-shadow: 5px 5px 20px 0px transparentize(#000, 0.5);
        .selectize-dropdown-content { 
            padding: 0px 0px; 
            background:$selectize_background_color;
            max-height: 250px !important;
            height: 250px !important;
            overflow: hidden;
            position: relative;
            .create {display:none}
            .option {
                color:$selectize_text_color !important;
                cursor:pointer;
                user-select: none;
                font-size:13px;
                padding: 8px 40px 8px 16px;
                animation-fill-mode: both !important;
                @include brk($m) {
                    font-size:13px;
                    padding: 9px 40px 9px 21px;
                }
                &:first-child {padding-top:18px;}
                &:last-child { padding-bottom:18px;}
                &.selected, &:hover {
                    background:darken($selectize_background_color, 10%);
                }
                @for $i from 1 through 20 {
                    &:nth-child(#{$i}) { 
                        animation-delay: (( 0.02s * $i) - 0.02s);
                        animation-duration: 0.3s;
                        animation-name:fade;
                    }
                }
            }
        }
    }
}

$this_background: $touche1;

#lightbox.devis-part {
    .selectize-input {
        background:darken($this_background, 4%) !important;
        input {
            &:hover {
                background:darken($this_background, 8%) !important;
            }
        }
        .item {
            &:hover {
                background:darken($this_background, 8%);
            }
        }
    }
    .selectize-dropdown {
        .selectize-dropdown-content { 
            background:darken($this_background, 8%);
            .option {
                &.selected, &:hover {
                    background:darken($this_background, 15%);
                }
            }
        }
    }
}



$this_background: #707070;

#lightbox.devis-pro {
    .selectize-input {
        background:transparentize(#191919, 0) !important;
        input {
            &:hover {
                background:darken($this_background, 12%) !important;
            }
        }
        .item {
            &:hover {
                background:darken($this_background, 12%);
            }
        }
    }
    .selectize-dropdown {
        .selectize-dropdown-content { 
            background:darken($this_background, 12%);
            .option {
                &.selected, &:hover {
                    background:darken($this_background, 22%);
                }
            }
        }
    }
}


