/* ================================================================================
    VARIABLES  
==================================================================================*/


//  COLORS  -------------------------------------------------


$main-background-color: #f1f1f1;
$footer-color: #002921;
$fc: $footer-color;

$touche1: #065696;
$touche2: #4a4337;
$touche3: #003359;
$touche4: #d8b463;

$color1: #b7d05f;
$color2: #0fb5c4;
$color3: #fdc418;
$color4: #20b196;
$color5: #909f5a;

$c1: $color1;
$c2: $color2;
$c3: $color3;
$c4: $color4;
$c5: $color5;

*::selection {
    background: $touche1; /* WebKit/Blink Browsers */
    color:#fff;
}



//  FONTS  ------------------------------------------------------


$family1 : 'Montserrat', sans-serif;
$family2 : 'Playfair Display', serif;
$family3 : 'Times', serif;

$f1 : $family1;
$f2 : $family2;
$f3 : $family3;

$color_default : #333;


$thin: 100;
$light: 300;
$normal: 400;
$regular: $normal;
$book: $normal;
$medium: 500;
$demi: 600;
$semi-bold: 600;
$bold: 700;
$heavy: 700;
$black: 900;



div, time, div, a, p, input, li, h1, h2, h3, h4, h5, h6 {
    // font-family: $family1;
    // color:$color_default;
}


p, h1, h2, h3, h4, h5, h6 {
    // line-height:1.65; 
    // font-weight:400;
    // letter-spacing: 0px;
    // word-spacing: 0px;
}
h1, h2, h3, h4, h5, h6 {
    line-height:1.2;
}

p, h1, h2, h3, h4, h5, h6 {user-select:text}
a, p, th, td {
    font-size:15px;
    @include brk($m) {
        font-size: 13px;
    }
}




//  HEADER HEIGHTS  -------------------------------------------

$header-height:85px;
$header-height-tablet:68px;
$header-height-mobile:68px;




//  WIDTHS ---------------------------------------------------

$width-left: 36.5%;
$width-right: 63.5%;






// ZONE DU CENTRE -------------------------------------------

$bannerContent-maxWidth-desktop: 1360px;   // 1220 + (2 * 70)

$padding-desktop: 70px;
$padding-tablet: 8%;
$padding-mobile: 8%;

// $bannerContent-maxWidth-extra: 1300px;
// $bannerContent-maxWidth-extra: calc(30% + 650px);
// $extra: min-width 2200px;    






// COLORS 

    $white:transparentize(#fff, 0);
    $light-grey: darken(#fff, 10%);
    $grey: darken(#fff, 25%);
    $dark-grey: darken(#fff, 40%);

    $yellow:#E8D761;
    $orange:#f39b1e;
    // $green:#92cdc3;
    $brown:#71645B;
    $bleu:#416681;
    $red: darken(red, 10%);
    $dark-red:#118888;
    $light-brown:#B2A47F;
    $rose:#f1999f;
    $mauve:#896a90;
    $purple:#6E36A7;








// SOCIAL COLORS --------------------------------

$twitter: #00aced;
$facebook: #3b5998;
$googleplus: #dd4b39;
$pinterest: #cb2027;
$linkedin: #007bb6;
$youtube: #bb0000;
$vimeo: #aad450;
$tumblr: #32506d;
$instagram: radial-gradient(#ffdc80, #fcaf45, #f77737, #f56040, #fd1d1d, #e1306c, #c13584, #833ab4, #5851db, #405de6);
$flickr: #ff0084;
$dribbble: #ea4c89;
$quora: #a82400;
$foursquare: #0072b1;
$forrst: #5B9A68;
$vk: #45668e;
$wordpress: #21759b;
$stumbleupon: #EB4823;
$yahoo: #7B0099;
$blogger: #fb8f3d;
$soundcloud: #ff3a00;

