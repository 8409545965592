





.header {
	position: fixed;
	left: 0;
	right: 0;
	z-index: 1002;
	min-height: 60px;
	padding: 0 15px;

	a.logo {
	    font-size: 24px;
	    color: #f2f2f2;
	    float: left;
	    margin-top: 15px;
	    text-transform: uppercase;
	}

	a.logo b {
	    font-weight: 900;
	}

	a.logo:hover, a.logo:focus {
	    text-decoration: none;
	    outline: none;
	}

	a.logo span {
	    color: $brand-primary;
	}
}