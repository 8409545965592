
.map-panel {
    padding: 0;
}

.btn-content-panel {
    float: right;
    margin-top: $padding-large-vertical * 2;
    margin-right: $grid-gutter-width;
}

.btn-back {
    float: left;
    margin-top: 20px;
    margin-right: $padding-base-horizontal;
    margin-left: $padding-base-horizontal;
}

table {
    .number {
        text-align: right;
    }
}

.table-edit-do {
    table-layout: fixed;

    tr > :first-child, tr > :nth-child(1) {
        width: 40px;
    }
}

.table-group {
    h1 {
        text-align: center;
        font-size: 14pt;
        margin-top: 0;
    }

    h3 {
        text-align: center;
        font-size: 10pt;
    }

    .form-control {
        max-width: 300px;
    }

    div {
        display: block;
        text-align: center;

        h1, .form-control, .btn {
            display: inline-block;
        }
    }
}

.content-panel {
    > h1, > h2, > h3, > h4, > h5, > h6, > p, > .pad {
        margin-left: $padding-large-horizontal;
    }

    > .table td:first-child, > .table th:first-child {
        padding-left: $padding-large-horizontal !important;
    }

    > .table td:last-child, > .table th:last-child {
        padding-right: $padding-large-horizontal !important;
    }
}

.login-logo {
    text-align: center;
    margin: $grid-gutter-width auto 0;

    img {
        max-width: 300px;
    }
}

.logo {
    width: 34px;
    height: 29px;
}

.form-login {
    .btn-theme {
        margin: $padding-large-vertical 0 !important;
    }

    .alert {
        margin-bottom: 0;
    }
}

.sidebar-menu {
    .fa:before {
        text-align: center;
        display: inline-block;
        min-width: 20px;
    }
}

.sidebar {
    &::before {
        display: block;
        content: '';
        background: #2f323a;
        position: fixed;
        height: 100%;
        width: 210px;
    }

    float: left;

    #sidebar {
        position: initial;
        overflow-y: scroll; // ny
    }
}

// Ny med aside:
//aside {
//    overflow-y: scroll;
//}

.form-horizontal.style-form {
    padding: 0 15px;
}

.form-horizontal .control-label {
    text-align: right;
}

.form-control-inline {
    display: inline-block;
    width: 50px;
    padding-right: 0 !important;
}

.angular-google-map-container {
    height: 250px;
}

.filter {
    .btn {
        margin-right: $padding-small-horizontal;
    }
}

.comment cite {
    display: block;
    padding-left: $padding-large-horizontal;
}

.top-menu {
    margin-top: 15px;
}

.top-menu li {
    margin-right: $padding-large-horizontal;
    display: inline-block;
}

.btn-icontop .fa {
    display: block;
    text-align: center;
    margin: $padding-base-vertical 0;
}

.table td {
    padding: 4px !important;
}

.table .btn-sm {
    padding: 3px 6px;
}

.customer-panel {
    text-align: center;
    padding-bottom: 0;
    margin-bottom: $grid-gutter-width;

    h3 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-left: $padding-base-horizontal;
        margin-right: $padding-base-horizontal;
    }
}

.customer-panel-command {
    display: block;
    height: 50px;
    padding: 15px;
    font-size: 15px;
    background: $brand-primary;
    color: white;
    border-bottom: 1px solid white;

    &:last-child {
        border-bottom: 0;
    }

    &:hover {
        color: white;
    }
}

@media (max-width: $screen-xs-max) {
    .customer-panel header * {
        margin-top: 0;
    }

    .customer-panel-commands {
        display: flex;
        align-content: stretch;
    }

    .customer-panel-command {
        border-bottom: none;
        border-left: 1px solid white;
        flex: 1;
    }

    .customer-panel-command:first-child {
        border-left: none;
    }


    .customer-panel-command-text {
        display: none;
    }
}

.no-hits {
    text-align: center;

    .fa {
        font-size: 6em;
        line-height: 1em;
        color: #eaeaea;
        text-shadow: 0 -2px rgba(0,0,0,0.2);
    }
}

.label {
    font-size: 100% !important;
}

.spinner {
    width: 50px;
    height: 50px;
    background-color: $brand-primary;
    margin: 100px auto;
    -webkit-animation: rotateplane .8s infinite ease-in-out;
    animation: rotateplane .8s infinite ease-in-out;
}

.selector a {
    margin-bottom: $padding-large-vertical;
}

.selector {
    margin-bottom: -$padding-large-vertical;
}

.as-sortable-item {
    -ms-touch-action: none;
    touch-action: none;
}

.as-sortable-item-handle {
    cursor: move;
}

.as-sortable-drag {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
    display: block;
    background: $brand-primary;
    color: white;
    padding: $padding-large-vertical;

    td {
        display: inline-block;
        margin-left: $padding-large-horizontal;
    }

    .btn {
        display: none;
    }
}

.as-sortable-droptarget {
    div {
        display: block;
        text-align: center;
        border: 2px dashed $gray-light;
        padding: $padding-large-vertical $padding-large-horizontal;
    }
}

.as-sortable-hidden {
    display: none !important;
}

.as-sortable-un-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@-webkit-keyframes rotateplane {
    0% {
        -webkit-transform: perspective(120px);
    }

    50% {
        -webkit-transform: perspective(120px) rotateY(180deg);
    }

    100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
}

@keyframes rotateplane {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@media print {
    body {
        font-size: 11px;
    }

    .table > thead > tr > th,
    .table > tbody > tr > th,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > tbody > tr > td,
    .table > tfoot > tr > td {
        padding: 3px !important;
        line-height: 1;
    }

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 20px;
    }

    h1, h2, h3 {
        margin-top: 0;
    }

    #sidebar, .header {
        display: none;
    }

    .wrapper {
        margin-top: 0;
    }

    #main-content {
        margin-left: 0;
    }

    .btn {
        display: none;
    }

    .content-panel, .form-panel {
        border-bottom: 1px solid $gray-light;

        &:last-child {
            border-bottom: 0;
        }
    }
}

.label {
    min-width: 30px;
    display: inline-block;
}

.empty-state {
    text-align: center;
    padding: $padding-base-vertical * 4 0;
}

.completed-state {
    text-align: center;
    padding: $padding-base-vertical * 4 0;
}

.uploader {
    position: relative;

    input[type="file"] {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
    }
}

.hard-copy {
    iframe, embed, object {
        width: 100%;
        min-height: 1024px;
    }
}

.signature-flow {
    display: inline-block;
    margin-right: $padding-large-horizontal;
}

.wizard-panel {
    background: #ffffff;
    margin: 10px 0;
    padding: 0;
    box-shadow: 0px 3px 2px #aab2bd;
    text-align: left;

    .step {
        padding: 10px;
    }
}




#hiddenPrintableArea {
    display: none;
}

/* Bootstrap 3 print fix*/
@media print {
    // .make-grid(sm);

    // .visible-xs {
    //     .responsive-invisibility();
    // }

    // .hidden-xs {
    //     .responsive-visibility();
    // }

    // .hidden-xs.hidden-print {
    //     .responsive-invisibility();
    // }

    // .hidden-sm {
    //     .responsive-invisibility();
    // }

    // .visible-sm {
    //     .responsive-visibility();
    // }

    // .hidden-print {
    //     display:none !important;
    // }
}

.show-print {
    display: none;

    .filter-info {
        margin-left: 1.5em;

        .filter-header {
            font-size: 1em;
            display: inline !important;
            font-weight: 700;
            margin: 0;
        }

        .filter-parameter {
            border: 1px solid gray;
            display: inline-block;
            padding: 0.3em;
            margin-right: 0.5em;
            margin-bottom: 0.5em;
        }
    }
}

@media print {
    .show-print {
        display: inherit;
    }
}
